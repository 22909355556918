import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Toggle from 'react-toggle-component';
import Layout from '../../components/Layout';
import styles from '../../css/aboutus.module.css';
import PageHeading from '../../components/PageHeading/PageHeading';
import TitleBar from '../../components/TitleBar/TitleBar';
import YouTube from 'react-youtube';
import '../../css/toggleComponent.css';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = { textViet: false };
  }

  toggleLang = () => {
    this.setState({ textViet: !this.state.textViet });
  };

  render() {
    const { textViet } = this.state;

    const purposeEng = (
      <Container>
        <p>The Eucharistic Youth Movement has two general purposes:</p>
        <ol>
          <li>
            To educate and train youths in becoming a better person as well as
            an excellent Christian
          </li>
          <li>
            To organize and to guide all youths in spreading the Good News of
            the Gospel and to actively be involved in working for the good of
            society through charitable services and helping others.
          </li>
        </ol>
      </Container>
    );

    const purposeViet = (
      <Container>
        <p>Phong Trào Thiếu Nhi Thánh Thể nhằm hai mục đích tổng quát:</p>
        <ol>
          <li>
            Đào Luyện thanh thiếu niên trở thành những con người kiện toàn và
            những Kitô Hữu hoàn hảo.
          </li>
          <li>
            Đoàn ngũ hoá và hướng dẫn thanh thiếu niên loan truyền Tin Mừng Chúa
            Kitô và góp phần xây dựng xã hội.
          </li>
        </ol>
      </Container>
    );

    const foundationEng = (
      <Container>
        <p>
          The Eucharistic Youth Movement utilizes God's Word in the Scriptures
          and the teaching of the Catholic Church as the basis for educating and
          guiding the youth in its activities.
        </p>
      </Container>
    );

    const foundationViet = (
      <Container>
        <p>
          Phong Trào Thiếu Nhi Thánh Thể lấy Lời Chúa trong Thánh Kinh và Giáo
          Huấn của Giáo Hội Công Giáo làm nền tảng để giáo dục và hướng dẫn giới
          trẻ trong các hoạt động.
        </p>
      </Container>
    );

    const idealEng = (
      <Container>
        <p>
          Eucharistic Youth Movement accepts the Eucharistic Jesus Christ as its
          focal point, as source of spiritual life, and as ideal for the life of
          its members.
        </p>
      </Container>
    );

    const idealViet = (
      <Container>
        <p>
          Phong Trào Thiếu Nhi Thánh Thể nhận Chúa Giêsu Thánh Thể là trung tâm
          điểm, là nguồn sống thiêng liêng và là lý tưởng sống của đời mình.{' '}
        </p>
      </Container>
    );

    const principlesEng = (
      <Container>
        <ol>
          <li>
            Live God’s Word and be united with the Eucharistic Christ through
            Prayer, Communion, Sacrifice, and Apostolic Work under the guidance
            of the Holy Spirit, particularly apostolic work by the youth: “The
            youth must do apostolic work first and directly to the youth”
            (Decree Lay Apostolicity, no. 12)
          </li>
          <li>
            Love and honor the Blessed Virgin Mary, Mother of the Savior, so
            that through Our Mother, we receive Christ and take Christ into our
            lives in a perfect way.
          </li>
          <li>
            Honor the Vietnamese Martyrs; follow their examples to become
            witnesses for the Good News of Christ.
          </li>
          <li>
            Love and obey the representative of Christ, the Pope, who is the
            leader of the Eucharistic Youth, and at the same time pray and
            implement the monthly wishes of the Holy Father.
          </li>
          <li>
            Improve humanity; protect and develop Vietnamese traditions and
            culture.
          </li>
        </ol>
        <br />
      </Container>
    );

    const principlesViet = (
      <Container>
        <ol>
          <li>
            Sống Lời Chúa và kết hợp với Chúa Giêsu Thánh Thể bằng Cầu Nguyện,
            Rước Lễ, Hy Sinh, và Làm Việc Tông Đồ dưới sự hướng dẫn của Chúa
            Thánh Thần, cách riêng làm tông đồ cho giới trẻ: “Giới trẻ phải làm
            tông đồ trước tiên và trực tiếp cho giới trẻ” (Sắc Lệnh Tông Đồ Giáo
            Dân, số 12).
          </li>
          <li>
            Yêu mến và tôn kính Đức Maria Mẹ Chúa Cứu Thế, để nhờ Mẹ chúng ta
            đón nhận Chúa và đem Chúa vào đời một cách tuyệt hảo.
          </li>
          <li>
            Tôn kính các Thánh Tử Đạo Việt Nam, noi gương và nên chứng tá Tin
            Mừng Chúa Kitô như các Ngài.
          </li>
          <li>
            Yêu mến và vâng phục vị đại diện Chúa Kitô là Đức Giáo Hoàng, thủ
            lãnh của Thiếu Nhi Thánh Thể, đồng thời cầu nguyện và thực hiện
            những ý chỉ hằng tháng của Ngài.
          </li>
          <li>
            Thăng tiến con người nhân bản. Bảo tồn và phát huy truyền thống văn
            hoá dân tộc Việt Nam.
          </li>
        </ol>
      </Container>
    );

    const dtnEng = (
      <Container>
        <ol>
          <li>
            As Eucharistic Youths, we offer our days each morning and enrich our
            lives with <b>prayers</b>.
          </li>
          <li>
            As Eucharistic Youths, we worship and receive the <b>Eucharist</b>,
            and visit regularly at the tabernacle.
          </li>
          <li>
            As Eucharistic Youths, we make <b>sacrifices</b> and find joy in
            carrying the Cross of Christ.
          </li>
          <li>
            As Eucharistic Youths, we depend on Mary’s intercession, and
            determine to be models worthy of Christ’s <b>apostles</b>.
          </li>
          <li>
            As Eucharistic Youths, we perform tasks of all sizes in the spirit
            of <b>obedience</b>, and do so with excellence.
          </li>
          <li>
            As Eucharistic Youths, we remain <b>pure</b> in our thoughts, words
            and actions.
          </li>
          <li>
            As Eucharistic Youths, we act with <b>charity</b>, and our generous
            hearts are always willing to help others.
          </li>
          <li>
            As Eucharistic Youths, we value <b>honesty</b> and gain the trust of
            people through our words and deeds.
          </li>
          <li>
            As Eucharistic Youths, we fulfill our <b>responsibilities</b>,
            regardless of the difficulty.
          </li>
          <li>
            As Eucharistic Youths, we partake in the <b>spiritual bouquet</b>,
            record each night and tally each week.
          </li>
        </ol>
      </Container>
    );

    const dtnViet = (
      <Container>
        <ol>
          <li>
            Thiếu Nhi mỗi sáng dâng ngày, Điểm tô đời sống hương bay{' '}
            <b>nguyện cầu</b>.
          </li>
          <li>
            Thiếu Nhi Thánh Thể nhiệm mầu, Tôn sùng <b>rước lễ</b>, nhà chầu
            viếng thăm.
          </li>
          <li>
            Thiếu Nhi Thánh Giá Chúa nằm, Nhìn lên phấn khởi chuyên cần{' '}
            <b>hy sinh</b>.
          </li>
          <li>
            Thiếu Nhi nhờ Mẹ đinh ninh, Quyết làm gương sáng xứng danh{' '}
            <b>tông đồ</b>.
          </li>
          <li>
            Thiếu Nhi mọi việc nhỏ to, Tinh thần <b>vâng phục</b> chuyên lo đậm
            đà.
          </li>
          <li>
            Thiếu Nhi đằm thắm <b>nết na</b>, Nói năng hành động nõn nà trắng
            trong.
          </li>
          <li>
            Thiếu Nhi <b>bác ái</b> một lòng, Tim luôn quảng đại mới mong giúp
            người.
          </li>
          <li>
            Thiếu Nhi <b>ngay thẳng</b> trọn đời, Nói làm đúng mực người người
            tin yêu.
          </li>
          <li>
            Thiếu Nhi dù khó trăm chiều, Chu toàn <b>bổn phận</b> mọi điều chăm
            chuyên.
          </li>
          <li>
            Thiếu Nhi thực hiện <b>hoa thiêng</b>, Chép ghi mỗi tối cộng biên
            mỗi tuần.
          </li>
        </ol>
      </Container>
    );

    return (
      <Layout>
        <Helmet
          title="VEYM | About"
          meta={[
            {
              name: 'description',
              content: `About VEYM/TNTT: The Vietnamese Eucharistic Youth Movement is a non-profit
              organization, under the leadership of the Catholic Church. Its aim
              is to teach youths to be virtuous people and good Christians. It
              also offers apostolate services for the youth. Throughout its years
              of service, the Movement has kept pace with social and cultural
              changes of the day in an attempt to reach out to the youth of all
              times.`,
            },
          ]}
        />
        <PageHeading>About Us</PageHeading>

        {/* <div className={styles.aboutImg}>
          <img
            className={`img-fluid ${styles.imgDarken}`}
            alt="TNTTDHTM"
            src={TNTTDHTM}
          />

          <h1 className={`display-3 ${styles.aboutImgCaption}`}>About Us</h1>
        </div> */}
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>About</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <TitleBar>Who Are We?</TitleBar>
        <Container>
          <br />
          <p>
            The Vietnamese Eucharistic Youth Movement is a non-profit
            organization, under the leadership of the Catholic Church. Its aim
            is to teach youths to be virtuous people and good Christians. It
            also offers apostolate services for the youth. Throughout its years
            of service, the Movement has kept pace with social and cultural
            changes of the day in an attempt to reach out to the youth of all
            times.
          </p>
          <br />
          <div className={`${styles.video}`}>
            <YouTube
              videoId="Aqm2xRGvrx8"
              // opts={{ width: '100%' }}
            />
          </div>
          <br />
          <hr />
        </Container>
        <br />
        <Container>
          <Toggle
            label="English"
            labelRight="Tiếng Việt"
            mode="select"
            checked={textViet}
            onToggle={this.toggleLang}
          />
        </Container>
        <br />

        <TitleBar>
          {!textViet ? <span>Our Purpose</span> : <span>Mục Đích</span>}
        </TitleBar>
        <br />
        {!textViet ? purposeEng : purposeViet}
        <br />

        <TitleBar>
          {!textViet ? <span>Our Foundation</span> : <span>Nền Tảng</span>}
        </TitleBar>
        <br />
        {!textViet ? foundationEng : foundationViet}
        <br />

        <TitleBar>
          {!textViet ? <span>Our Ideal</span> : <span>Lý Tưởng</span>}
        </TitleBar>
        <br />
        {!textViet ? idealEng : idealViet}
        <br />

        <TitleBar>
          {!textViet ? <span>Our Principles</span> : <span>Tôn Chỉ</span>}
        </TitleBar>
        <br />
        {!textViet ? principlesEng : principlesViet}

        <TitleBar>
          {!textViet ? <span>Our 10 Vows</span> : <span>10 Điều Tâm Niệm</span>}
        </TitleBar>
        <br />
        {!textViet ? dtnEng : dtnViet}

        <br />
        <br />

        <br />
      </Layout>
    );
  }
}

export default AboutUs;

export const frontmatter = {
  title: 'About us',
  category: 'pages',
  path: '/aboutus',
  description: 'About VEYM',
  tags:
    'about us, veym, tntt, thieu nhi thanh the, vietnamese eucharistic youth movement, muc dich, purpose, mission, foundation, nen tang, ideal, ly tuong, principles, ton chi, 10 dieu tam niem, who are we, overview',
};
